<template>
<div class="section-box">
	<div class="section-content">
		<div class="sub-title">{{ aftersaleType == String(aftersaleTypeEnum.upgrade) ? "升班订单" : "转班订单" }}</div>
		<el-table
			border
			highlight-current-row
			style="width: 100%; margin-bottom: 18px"
			header-cell-class-name="normal-table-header"
			:data="newOrder"
			v-if="aftersaleType == String(aftersaleTypeEnum.upgrade)"
		>
			<template v-for="item of orderColumns">
				<el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'createTime'" :key="item.prop" :label="item.label" min-width="160">
					<template v-slot:default="{ row: { createTime } }">
						<p v-if="createTime">{{ createTime | formatDate2('YYYY-MM-DD HH:mm:ss') }}</p>
						<p v-else>--</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'allPay'" :key="item.prop" :label="item.label" min-width="160">
					<template v-slot:default="{ row: { allPay } }">
						<p>{{ allPayMap[allPay] }}</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderStatus'" :key="item.prop" :label="item.label" min-width="160">
					<template v-slot:default="{ row: { orderStatus } }">
						<p>{{ orderStatusMap[orderStatus] || '--' }}</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderType'" :key="item.prop" :label="item.label" min-width="160">
					<template v-slot:default="{ row: { orderType } }">
						<p>{{ orderTypeMap[orderType] }}</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'completionTime'" :key="item.prop" :label="item.label" min-width="160">
					<template v-slot:default="{ row: { completionTime } }">
						<p>{{ completionTime  | formatDate2('YYYY-MM-DD HH:mm:ss')  }}</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'do'" :key="item.prop" :label="item.label" :fixed="item.fixed">
					<template v-slot:default="{ row }">
						<div style="color: #337AFF; cursor: pointer" class="seeBtn" @click="goDetail(row)" v-if="row.orderNo">查看详情</div>
						<span v-else>--</span>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true"
					v-else
					:key="item.label"
					:label="item.label"
					:prop="item.prop"
					:width="item.width"
					:fixed="item.fixed"
				>
					<template v-slot:default="{row}">
						<span v-if="['goodsSeriesName', 'commodityName', 'goodsCategoryName', 'orderNo'].includes(item.prop)">{{row[item.prop] | empty}}</span>
						<span v-else>{{ row[item.prop] }}</span>
					</template>
				</el-table-column>
			</template>
		</el-table>
		<!-- 转班 -->
		<el-table
			border
			highlight-current-row
			style="width: 100%; margin-bottom: 18px"
			header-cell-class-name="normal-table-header"
			:data="newOrder"
			v-else
		>
			<template v-for="item of orderColumnszhuan">
				<el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'createTime'" :key="item.prop" :label="item.label" :min-width="item.width">
					<template v-slot:default="{ row: { createTime } }">
						<p v-if="createTime">{{ createTime | formatDate2 }}</p>
						<p v-else>--</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'allPay'" :key="item.prop" :label="item.label" :min-width="item.width">
					<template v-slot:default="{ row: { allPay } }">
						<p>{{ allPayMap[allPay] }}</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderStatus'" :key="item.prop" :label="item.label" :min-width="item.width">
					<template v-slot:default="{ row: { orderStatus } }">
						<p>{{ orderStatusMap[orderStatus] || '--' }}</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderType'" :key="item.prop" :label="item.label" :min-width="item.width">
					<template v-slot:default="{ row: { orderType } }">
						<p>{{ orderTypeMap[orderType] }}</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'completionTime'" :key="item.prop" :label="item.label" :min-width="item.width">
					<template v-slot:default="{ row: { completionTime } }">
						<p>{{ completionTime | formatDate2('YYYY-MM-DD HH:mm:ss') }}</p>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'do'" :key="item.prop" :label="item.label" :fixed="item.fixed">
					<template v-slot:default="{ row }">
						<div style="color: #337AFF; cursor: pointer" class="seeBtn" @click="goDetail(row)" v-if="row.orderNo">查看详情</div>
						<span v-else>--</span>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true"
					v-else
					:key="item.label"
					:label="item.label"
					:prop="item.prop"
					:width="item.width"
					:fixed="item.fixed"
				>
					<template v-slot:default="{ row }">
							<span v-if="['goodsSeriesName', 'commodityName', 'goodsCategoryName', 'orderNo'].includes(item.prop)">{{row[item.prop] | empty}}</span>
							<span v-else-if="['totalMoney', 'amountReceived'].includes(item.prop)">{{ row[item.prop] | decimal2 }}</span>
							<span v-else>{{ row[item.prop] }}</span>
					</template>
				</el-table-column>
			</template>
		</el-table>
	</div>
	<div class="section-line"></div>
</div>
</template>

<script>
import { aftersaleTypeEnum, allPayMap, orderStatusMap, orderTypeMap } from '@/views/order/constants'
export default {
	props: {
		newOrder: Array,
		aftersaleType:[String, Number]
	},
	data(){
		return {
			aftersaleTypeEnum,
			orderTypeMap,
			orderStatusMap,
			allPayMap,
			// 升班通用columns
      orderColumns: [
        { label: "订单号", prop: "orderNo", width: "160px", fixed: true },
        { label: "报读商品", prop: "goodsName", width: "160px", fixed: true },
        { label: "班型", prop: "commodityName", width: "160px"},
        { label: "产品线", prop: "productName" },
        { label: "商品分类", prop: "goodsCategoryName" },
        { label: "产品系列", prop: "goodsSeriesName" },
        { label: "学习账号", prop: "mobile" },
        { label: "学员姓名", prop: "nickName" },
        { label: "身份证号", prop: "idCard" },
        { label: "客户编号", prop: "customerCode" },
        { label: "应收金额", prop: "totalMoney" },
        { label: "已收金额", prop: "amountReceived" },
        { label: "订单状态", prop: "orderStatus" },
        { label: "报名类型", prop: "orderType" },
        { label: "招生老师", prop: "teacherRecruitName" },
        { label: "上课校区", prop: "signSchoolName" },
        { label: "报名时间", prop: "createTime" },
        { label: "操作", prop: "do", width: "100px", fixed: "right" },
      ],
      //转班
      orderColumnszhuan: [
        { label: "订单号", prop: "orderNo", width: 160, fixed: true },
        { label: "报读商品", prop: "goodsName", width: 160, fixed: true },
        { label: "班型", prop: "commodityName", width: 100},
        { label: "产品线", prop: "productName", width: 160 },
        { label: "商品分类", prop: "goodsCategoryName" },
        { label: "产品系列", prop: "goodsSeriesName", width: 120 },
        { label: "学习账号", prop: "mobile", width: 120 },
        { label: "学员姓名", prop: "nickName", width: 120  },
        { label: "身份证号", prop: "idCard", width: 188 },
        { label: "客户编号", prop: "customerCode", width: 120  },
        { label: "应收金额", prop: "totalMoney", width: 100  },
        { label: "已收金额", prop: "amountReceived", width: 100 },
        { label: "订单状态", prop: "orderStatus", width: 80 },
        { label: "报名类型", prop: "orderType", width: 80 },
        { label: "招生老师", prop: "teacherRecruitName", width: 120 },
        { label: "上课校区", prop: "signSchoolName", width: 120  },
        { label: "报名时间", prop: "createTime", width: 160  },
        { label: "操作", prop: "do", width: "100px", fixed: "right" },
      ],
		}
	},
	methods: {
		goDetail(row){
			this.$emit('goDetail', row)
		}
	}
}
</script>

<style lang="scss" scoped>
.section-box{
	.section-content{
		width: 94%;
		margin: 0 auto;
		padding-top: 18px;
		padding-bottom: 18px;
	}
	.sub-title {
		font-size: 16px;
		font-weight: bold;
		color: #333333;
		padding-bottom: 13px;
	}
	.cell p{
		margin-bottom: 0;
	}
	.section-line{
		height: 12px;
		background: #F5F5FA;
	}
}
</style>