var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-box" }, [
    _c(
      "div",
      { staticClass: "section-content" },
      [
        _c("div", { staticClass: "sub-title" }, [
          _vm._v(
            _vm._s(
              _vm.aftersaleType == String(_vm.aftersaleTypeEnum.upgrade)
                ? "升班订单"
                : "转班订单"
            )
          )
        ]),
        _vm.aftersaleType == String(_vm.aftersaleTypeEnum.upgrade)
          ? _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-bottom": "18px" },
                attrs: {
                  border: "",
                  "highlight-current-row": "",
                  "header-cell-class-name": "normal-table-header",
                  data: _vm.newOrder
                }
              },
              [
                _vm._l(_vm.orderColumns, function(item) {
                  return [
                    item.prop === "createTime"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": "160"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var createTime = ref.row.createTime
                                  return [
                                    createTime
                                      ? _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate2")(
                                                createTime,
                                                "YYYY-MM-DD HH:mm:ss"
                                              )
                                            )
                                          )
                                        ])
                                      : _c("p", [_vm._v("--")])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "allPay"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": "160"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var allPay = ref.row.allPay
                                  return [
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.allPayMap[allPay]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "orderStatus"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": "160"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var orderStatus = ref.row.orderStatus
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.orderStatusMap[orderStatus] ||
                                            "--"
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "orderType"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": "160"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var orderType = ref.row.orderType
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.orderTypeMap[orderType])
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "completionTime"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": "160"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var completionTime = ref.row.completionTime
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate2")(
                                            completionTime,
                                            "YYYY-MM-DD HH:mm:ss"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "do"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            fixed: item.fixed
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    row.orderNo
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "seeBtn",
                                            staticStyle: {
                                              color: "#337AFF",
                                              cursor: "pointer"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goDetail(row)
                                              }
                                            }
                                          },
                                          [_vm._v("查看详情")]
                                        )
                                      : _c("span", [_vm._v("--")])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _c("el-table-column", {
                          key: item.label,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            prop: item.prop,
                            width: item.width,
                            fixed: item.fixed
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    [
                                      "goodsSeriesName",
                                      "commodityName",
                                      "goodsCategoryName",
                                      "orderNo"
                                    ].includes(item.prop)
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("empty")(row[item.prop])
                                            )
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(row[item.prop]))
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                  ]
                })
              ],
              2
            )
          : _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-bottom": "18px" },
                attrs: {
                  border: "",
                  "highlight-current-row": "",
                  "header-cell-class-name": "normal-table-header",
                  data: _vm.newOrder
                }
              },
              [
                _vm._l(_vm.orderColumnszhuan, function(item) {
                  return [
                    item.prop === "createTime"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": item.width
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var createTime = ref.row.createTime
                                  return [
                                    createTime
                                      ? _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate2")(createTime)
                                            )
                                          )
                                        ])
                                      : _c("p", [_vm._v("--")])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "allPay"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": item.width
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var allPay = ref.row.allPay
                                  return [
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.allPayMap[allPay]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "orderStatus"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": item.width
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var orderStatus = ref.row.orderStatus
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.orderStatusMap[orderStatus] ||
                                            "--"
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "orderType"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": item.width
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var orderType = ref.row.orderType
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.orderTypeMap[orderType])
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "completionTime"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            "min-width": item.width
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var completionTime = ref.row.completionTime
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate2")(
                                            completionTime,
                                            "YYYY-MM-DD HH:mm:ss"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : item.prop === "do"
                      ? _c("el-table-column", {
                          key: item.prop,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            fixed: item.fixed
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    row.orderNo
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "seeBtn",
                                            staticStyle: {
                                              color: "#337AFF",
                                              cursor: "pointer"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goDetail(row)
                                              }
                                            }
                                          },
                                          [_vm._v("查看详情")]
                                        )
                                      : _c("span", [_vm._v("--")])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _c("el-table-column", {
                          key: item.label,
                          attrs: {
                            "show-overflow-tooltip": true,
                            label: item.label,
                            prop: item.prop,
                            width: item.width,
                            fixed: item.fixed
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    [
                                      "goodsSeriesName",
                                      "commodityName",
                                      "goodsCategoryName",
                                      "orderNo"
                                    ].includes(item.prop)
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("empty")(row[item.prop])
                                            )
                                          )
                                        ])
                                      : [
                                          "totalMoney",
                                          "amountReceived"
                                        ].includes(item.prop)
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("decimal2")(row[item.prop])
                                            )
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(row[item.prop]))
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                  ]
                })
              ],
              2
            )
      ],
      1
    ),
    _c("div", { staticClass: "section-line" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }